<script setup lang="ts">

const props = defineProps({
    title: {
        type: String,
        default: null,
    },
});

const route = useRoute()
const router = useRouter()


let crumbs: any[] = []
const fullPath = route.fullPath
const params = fullPath.startsWith('/')
    ? fullPath.substring(1).split('/')
    : fullPath.split('/')
crumbs = []

let path = ''

const capitalize = (s: string) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

const pascalCase = (s: string) => {
    if (typeof s !== 'string') return ''
    return s
        .split('-')
        .map((part) => capitalize(part))
        .join('')
}

params.forEach((param, index) => {

    path = `${path}/${param}`
    const match: RegExpMatchArray | null = route.fullPath.match(path)

    crumbs.push({
        title: pascalCase(param.replace(/-/g, ' ')),
        path: path,
        ...match,
    })
})

</script>

<template>
    <ol class="list list-none text-xs">
        <li class="inline">
            <NuxtLink to="/">
                <span>Front Page</span>
            </NuxtLink>
        </li>
        <li v-for="(crumb, index) in crumbs" :key="index" class="inline">
            <NuxtLink :to="crumb.path">
                <span>{{ route.fullPath === crumb.path && title ? title : crumb.title }}</span>
            </NuxtLink>
        </li>
    </ol>
</template>

<style scoped>
li:after {
    content: ' » ';
    display: inline;
    font-size: 1.2em;
    color: #888;

    padding: 0 0.0725em 0 0.15em;
}

li:last-child:after {
    content: '';
}
</style>
